<template>
  <div class="DataScience" >
    <section id="data-main-section">
      <div class="container">
        <div class="row section-title-margin">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title">
            Data science
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12 ">
            <p class="section-slogan">
              Des données valorisées et utilisables directement par vos équipes.
            </p>
            <p class="section-explanation">
              Notre équipe se doit d'être la plus talentueuse pour vous.
              Nous donnons du sens à vos données et construisons des solutions
              technologiques vous permettant d'en tirer un maximum de valeur.<br>
              Notre équipe met à votre disposition des compétences d'intelligence marketing
              et d'analyse de marché. Nous vous proposons également des solutions technologiques
              clés en main ou construisons pour vous des solutions sur-mesure.
            </p>
          </div>
        </div>
      </div>
    </section>


    <section id="data-proposition-section">
      <div class="container">
        <div class="row section-title-margin">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title">
              Nos propositions
            </h3>
          </div>
        </div>
        <div class="data-alt-tabs">
          <a class="mt-2" block href="javascript:void(0);" v-b-toggle.base-prospection-collapse>
            Construction automatisée de bases de prospection
          </a>
        </div>
        <div>
          <b-collapse class=" mt-4 section-explanation text-explanation-on-white" id="base-prospection-collapse" accordion="data-proposition-accordion" role="tabpanel">
          <p>
            Nous avons construit des algorithmes de webscrapping
            permettant de récupérer une base complète, à jour et RGPD-compatible
            des entreprises et indépendants de France selon vos critères : <em>secteur d’activités,
            zone géographique, taille d’entreprise, catégorie juridique, etc.</em>
          </p>
          <p>
            Nos algorithmes nous permettent de récupérer et de qualifier cette base
            avec des informations économiques (secteur, effectif, chiffre d’affaires, etc.),
            géographiques (adresse, code postal, pays, etc.), de gouvernance (mandataires sociaux, directeur général)
            ou de contact (numéro de téléphone, site web).
          </p>
          </b-collapse>
        </div>


        <div class="data-alt-tabs">
          <a class="mt-2" block href="javascript:void(0);" v-b-toggle.analyse-portefeuille-collapse>
            Analyse de portefeuille
          </a>
        </div>
        <div>
          <b-collapse class=" mt-4 section-explanation text-explanation-on-white" id="analyse-portefeuille-collapse" accordion="data-proposition-accordion" role="tabpanel">
            <p>
              Nos équipes data analysent votre portefeuille client et s'appuient sur
              nos algorithmes de clusterisation et de segmentation pour valoriser vos données :
              opportunités de vente croisée, risque de churn, prédiction du comportement d’achat, etc.
            </p>
            <p>
              En partenariat avec les équipes du cabinet Mayer Prezioso,
              nous vous accompagnons pour adapter et déployer votre stratégie marketing et commerciale.
            </p>
          </b-collapse>
        </div>


        <div class="data-alt-tabs">
          <a class="mt-2" block href="javascript:void(0);" v-b-toggle.analyse-sectorielle-collapse>
            Analyse sectorielle
          </a>
        </div>
        <div>
          <b-collapse class=" mt-4 section-explanation text-explanation-on-white" id="analyse-sectorielle-collapse" accordion="data-proposition-accordion" role="tabpanel">
            <p>
              Nous réalisons des analyses sectorielles poussées pour doter
              vos équipes commerciales d’éléments d’acculturation sur le secteur cible et d’outils clés en main
              de conquête commerciale.
            </p>
            <p>
              Nous vous livrons une monographie sectorielle comprenant les caractéristiques
              essentielles (produit, dynamisme de l’offre, comportement de la demande, tendances de marché),
              politiques (réglementations, politiques publiques, innovations de branches), stratégiques
              (chaine de valeur, stratégie des acteurs du secteur, stratégies disruptives des nouveaux entrants)
              et économiques (performances, chiffre d’affaires, rentabilités, etc.).
            </p>
            <p>
              Nous vous proposons également des outils d’aides à la vente
              (glossaire, lexique, personae) pour que vos commerciaux soient les plus efficaces possibles sur le terrain.
            </p>
          </b-collapse>
        </div>

        <div class="data-alt-tabs">
          <a class="mt-2" block href="javascript:void(0);" v-b-toggle.construction-solutions-ia-collapse>
            Construction de solutions d’IA sur-mesure
          </a>
        </div>
        <div>
          <b-collapse class=" mt-4 section-explanation text-explanation-on-white" id="construction-solutions-ia-collapse" accordion="data-proposition-accordion" role="tabpanel">
            <p>
              Nous construisons pour vous des solutions sur-mesure utilisant les dernières technologies
              d’intelligence artificielle. Nos équipes sont spécialisées sur le traitement de l’image
              (reconnaissance d’image, classification, segmentation, amélioration) et du texte
              (analyse de sentiments, reconnaissance optique de caractères, classification d'emails, etc.) utilisant nos algorithmes de deep learning.
            </p>
          </b-collapse>
        </div>
      </div>
    </section>


    <section id="data-technologies-section">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title section-title-margin">
              Nos technologie utilisées
            </h3>
          </div>
        </div>

        <div class="row" style="margin-top: 20px;">
          <div class="col-lg-2 col-sm-6 col-12 text-center align-self-center">
            <img class="img-fluid" :src="require('@/static/images/python.png')">
          </div>
          <div class="col-lg-2 col-sm-6 mt-sm-0 col-12 mt-4 text-center align-self-center">
            <img class="img-fluid" :src="require('@/static/images/tensorflow.png')">
          </div>
          <div class="col-lg-2 mt-lg-0 col-sm-6 col-12 mt-4 text-center align-self-center">
            <img class="img-fluid" :src="require('@/static/images/numpy.png')">
          </div>
          <div class="col-lg-2 mt-lg-0 col-sm-6 col-12 mt-4 text-center align-self-center">
            <img class="img-fluid" :src="require('@/static/images/scipy.png')">
          </div>
          <div class="col-lg-2 mt-lg-0 col-sm-6 col-12 mt-4 text-center align-self-center">
            <img class="img-fluid" :src="require('@/static/images/pytorch.png')">
          </div>
          <div class="col-lg-2 mt-lg-0 col-sm-6 col-12 mt-4 text-center align-self-center">
            <img class="img-fluid" :src="require('@/static/images/scikit.png')">
          </div>
        </div>
      </div>
    </section>

    <section id="data-launch-project-section">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title section-title-margin">
              Lancez votre projet
            </h3>
          </div>
        </div>

        <div class="row basic-alt-tabs text-explanation-on-black">
          <div class="col-12 text-center">
            <b-button to='/contact' class="p-3" variant="outline-dark" size='lg'>
              Nous contacter
            </b-button>
          </div>
        </div>
      </div>
    </section>

    <footerComponent />
  </div>
</template>

<script>
const footerComponent = () => import('@/components/footerComponent')

export default {
  name: 'DataScience',
  components: {
    footerComponent,
  },
}
</script>

<style>
#data-main-section {
  padding-top: 20px;
  padding-bottom: 80px;
  background-color: black;
  color: white;
}

#data-proposition-section {
  padding-bottom: 80px;
}


#data-technologies-section {
  margin-top: 25px;
  padding-bottom: 80px;
  background-color: black;
  color: white;
}

#data-launch-project-section {
  padding-bottom: 80px;
}


.data-alt-tabs {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1em;
    border: none;
    margin-top: 30px;
}

.data-alt-tabs > a {
    text-decoration: none;
    color: #6E6E6E;
    background: none !important;
    border: none !important;
}

.data-alt-tabs > a:hover {
    text-decoration: none;
    color: black;
    background: none !important;
    border: none !important;
}

.data-alt-tabs > a.data-tab-active {
    text-decoration: none;
    color: black;
    background: none !important;
    border: none !important;
}

@media (min-width: 480px) {
  .data-alt-tabs {
    font-size: 18px;
    letter-spacing: 0.2em;
  }
}


</style>
